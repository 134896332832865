<template>
  <v-container v-if="Company" class="pa-0">
    <v-row justify="center">
      <v-col cols="12" sm="8">
        <!-- <r-card v-if="Company">
          <template #header> -->
        <v-row no-gutters class="mb-4">
          <h2>{{ $t('PageTitles.CompanyDetails') }}</h2>
        </v-row>
        <!-- </template> -->
        <v-simple-table dense class="noHoverEffect pa-2">
          <tbody>
            <tr>
              <td class="font-weight-bold" style="width: 200px">{{ $t('CompaniesListHeaders.Name') }}</td>
              <td>{{ Company.name }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="width: ">{{ $t('CompaniesListHeaders.Cvr') }}</td>
              <td>{{ Company.cvr }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="width: ">{{ $t('CompaniesListHeaders.PhoneNumber') }}</td>
              <td>{{ Company.phone }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('CompaniesListHeaders.Country') }}</td>
              <td>{{ Company.country }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('CompaniesListHeaders.Address') }}</td>
              <td>{{ Company.address }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('CompaniesListHeaders.AmountOfUsers') }}</td>
              <td>{{ Company.usersCount }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-card-actions class="px-0 mt-4">
          <v-spacer />
          <v-btn :block="$vuetify.breakpoint.mobile" @click="EditCompany" :color="$vuetify.theme.dark ? 'black' : 'white'" depressed>
            <v-icon left>mdi-pencil</v-icon>
            {{ $t('ButtonsLabel.Edit') }}
          </v-btn>
        </v-card-actions>

        <v-row no-gutters align="center" class="mb-4">
          <v-col cols="2" sm="4">
            <h2>{{ $t('PageTitles.Users') }}</h2>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :class="$vuetify.breakpoint.mobile ? 'mt-4' : ''"
              outlined
              hide-details
              dense
              v-model="search"
              :label="$t('message.Search')"
              background-color="searchField"
            />
          </v-col>
          <v-col cols="12" md="4" align="right" v-if="isManager">
            <v-btn
              :class="$vuetify.breakpoint.mobile ? 'mt-4' : ''"
              :block="$vuetify.breakpoint.mobile"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              depressed
              large
              @click="addUser"
            >
              <v-icon left>mdi-account-plus-outline</v-icon>
              {{ $t('ButtonsLabel.AddUser') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          class="mt-4"
          :items="users"
          :search="search"
          :headers="getHeaders"
          :items-per-page="15"
          sort-desc
          @click:row="showUserDetails"
          :footer-props="footerProps"
        >
          <template #[`item.disabled`]="{ item }">
            {{ $util.formatDisabledState(item.disabled) }}
          </template>
          <template #[`item.name`]="{ item }">
            <router-link :to="{ name: 'UserDetails', params: { Id: item.id } }">{{ item.name }}</router-link>
          </template>
        </v-data-table>
        <div v-if="Company.impact">
          <v-row no-gutters align="center" class="mt-4">
            <v-col cols="2">
              <h2 class="my-4">{{ $t('ImpactLevelItems.Impact') }}</h2>
            </v-col>
          </v-row>
          <v-data-table
            :items="Company.impact.impactLevels"
            :headers="getImpactHeaders"
            :items-per-page="15"
            hide-default-footer
            @click:row="editRow"
            :footer-props="footerProps"
          >
            <template #[`item.impactLevelID`]="{ item }">
              {{ $util.impactLeveIdToText(item.impactLevelID) }}
            </template>

            <template #[`item.action`]="{ item }" v-if="isManager">
              <div class="text-right">
                <v-btn icon @click="editRow(item)" :title="$t('DialogsTitles.EditImpact')">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <create-edit-user ref="CreateEditUserRef" @user-updated="bubbleEmit" />
    <create-edit-company ref="CreateEditCompanyRef" @company-updated="bubbleEmit" />
    <EditImpactLevelRow @impact-updated="saveUpdate" ref="editImpactLevelRef"></EditImpactLevelRow>
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditUser from '@/components/Dialogs/CreateEditUser.vue';
import CreateEditCompany from '@/components/Dialogs/CreateEditCompany.vue';
import EditImpactLevelRow from '@/components/Dialogs/EditImpactLevelRow.vue';
import { mapGetters } from 'vuex';
export default {
  components: { CreateEditUser, CreateEditCompany, EditImpactLevelRow },
  data: () => ({
    Company: null,
    users: [],
    search: null,
    footerProps: { 'items-per-page-options': [15, 30, 50, 100] },
  }),
  computed: {
    ...mapGetters(['isManager']),
    getHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('UserListHeaders.Name'), value: 'name' },
        { text: t('UserListHeaders.Email'), value: 'email' },
        { text: t('UserListHeaders.PhoneNumber'), value: 'phoneNumber' },
        { text: t('UserListHeaders.CompanyName'), value: 'companyName' },
        { text: t('UserDetailsListHeaders.State'), value: 'disabled' },
      ];
    },
    getImpactHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('ImpactLevelItems.ImpactLevel'), value: 'impactLevelID' },
        { text: t('ImpactLevelItems.TimePercentage'), value: 'timeMedian' },
        { text: t('ImpactLevelItems.ResourcesPercentage'), value: 'resourcesMedian' },
        { text: t('ImpactLevelItems.ImpactPercentage'), value: 'impactPercentage' },
        { text: '', value: 'action' },
      ];
    },
  },
  methods: {
    async getCompany() {
      this.Company = await apiService.getCompanyById(this.$route.params.Id);
      this.users = this.Company.users;
    },
    async saveUpdate() {
      this.getCompany();
    },
    EditCompany() {
      this.$refs.CreateEditCompanyRef.editCompany(this.Company);
    },
    showUserDetails(user) {
      this.$router.push({ name: 'UserDetails', params: { Id: user.id } });
    },
    addUser() {
      this.$refs.CreateEditUserRef.createUser(this.$route.params.Id);
    },
    bubbleEmit() {
      this.getCompany();
    },
    editRow(impactLevel) {
      this.$refs.editImpactLevelRef.EditImpact(impactLevel);
    },
  },
  created() {
    this.getCompany();
  },
};
</script>
